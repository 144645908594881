import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "row justify-content-center" }
const _hoisted_3 = { class: "col-md-8" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = {
  key: 0,
  class: "p-6 p-md-5"
}
const _hoisted_6 = { class: "card-title text-center mb-4" }
const _hoisted_7 = { class: "text-center mb-5" }
const _hoisted_8 = { class: "d-flex flex-column gap-4" }
const _hoisted_9 = { class: "option-card" }
const _hoisted_10 = { class: "text-muted small" }
const _hoisted_11 = { class: "option-card" }
const _hoisted_12 = { class: "text-muted small" }
const _hoisted_13 = {
  key: 1,
  class: "d-flex justify-content-center flex-column flex-md-row text-center"
}
const _hoisted_14 = {
  key: 0,
  class: "px-1"
}
const _hoisted_15 = {
  key: 1,
  class: "px-1"
}
const _hoisted_16 = { class: "btn btn-link btn-sm m-0 p-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (!_ctx.userType)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t("signup.user_type.select_title")), 1),
                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("signup.user_type.select_description")), 1),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("button", {
                      class: "btn btn-lg btn-outline-primary w-100 mb-2",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectUserType('company')))
                    }, _toDisplayString(_ctx.$t("signup.user_type.company.button")), 1),
                    _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t("signup.user_type.company.description")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("button", {
                      class: "btn btn-lg btn-outline-primary w-100 mb-2",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectUserType('private')))
                    }, _toDisplayString(_ctx.$t("signup.user_type.private.button")), 1),
                    _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t("signup.user_type.private.description")), 1)
                  ])
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("signup.user_type.selection_prefix")), 1),
                (_ctx.userType === 'private')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.$t("signup.user_type.selected_private")), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.$t("signup.user_type.selected_company")), 1)),
                _createElementVNode("button", _hoisted_16, [
                  _createVNode(_component_FontAwesomeIcon, {
                    icon: "rotate-left",
                    onClick: _ctx.resetUserType
                  }, null, 8, ["onClick"])
                ])
              ]))
        ])
      ])
    ])
  ]))
}