// client/src/components/signup/lv/generator-fields.ts

import { Composer } from "vue-i18n";
import { useValidators } from "@/composables/useValidators";

export interface GeneratorFieldConfig {
  title: string;
  model: string;
  type: "string" | "number" | "boolean" | "date" | "dropdown" | "radio";
  options?: { value: string | number | boolean; label: string }[];
  validation?: [any]; //FIXME: any
  editable?: boolean;
  condition?: (data: any) => boolean;
}

export function getGeneratorFields(t: Composer["t"]) {
  const validators = useValidators(t);

  const generatorFields: GeneratorFieldConfig[] = [
    // Basic device information
    {
      title: "device_name",
      model: "device_name",
      type: "string",
      validation: [validators.required],
    },
    {
      title: "device_technology",
      model: "device_technology",
      type: "dropdown",
      options: [
        { value: "solar", label: "device_technology_solar" },
        { value: "wind", label: "device_technology_wind" },
        { value: "hydro", label: "device_technology_hydro" },
      ],
      validation: [validators.required],
    },

    // Power and technical details
    {
      title: "device_power",
      model: "device_power",
      type: "number",
      validation: [validators.required],
    },
    {
      title: "device_power_in_permit",
      model: "device_power_in_permit",
      type: "number",
      validation: [validators.required],
    },
    {
      title: "device_eic",
      model: "device_eic",
      type: "string",
      validation: [validators.required],
    },
    {
      title: "only_device_output",
      model: "only_device_output",
      type: "boolean",
      validation: [validators.required],
    },

    // Location information
    {
      title: "device_address",
      model: "device_address",
      type: "string",
      validation: [validators.required],
    },
    {
      title: "lat",
      model: "lat",
      type: "number",
      validation: [validators.required],
    },
    {
      title: "lng",
      model: "lng",
      type: "number",
      validation: [validators.required],
    },

    // Grid connection details
    {
      title: "device_grid_operator",
      model: "device_grid_operator",
      type: "dropdown",
      options: [
        { value: "TSO", label: "grid_operator_tso" },
        { value: "DSO", label: "grid_operator_dso" },
      ],
      validation: [validators.required],
    },
    {
      title: "grid_contract_start_date",
      model: "grid_contract_start_date",
      type: "date",
      validation: [validators.required, validators.reasonableDate],
    },
  ] as GeneratorFieldConfig[];

  return {
    generatorFields,
  };
}
