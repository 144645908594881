<!-- client/src/components/signup/lv/GeneratorReviewComponent.vue -->

<template>
  <div class="generator-review card p-4 mb-3" novalidate>
    <h3 class="mb-1 mb-sm-4">
      {{ $t("signup.add_generator_component.title") }}
    </h3>
    <DynamicGeneratorField
      v-for="field in generatorFields"
      :key="field.title"
      :field="field"
      v-model="localGenerator[field.model]"
    />
    <div class="row mb-2 g-2">
      <label for="has-subsidy" class="col-sm-12 pb-0 col-form-label">
        {{ $t("signup.add_generator_component.subsidy_question") }}
      </label>
      <div class="col-sm-11">
        <div class="form-check" role="button">
          <input
            class="form-check-input pe-auto"
            role="button"
            type="radio"
            name="subsidy_radio"
            id="has-subsidy-no"
            :value="false"
            v-model="hasSubsidy"
          />
          <label class="form-check-label" for="has-subsidy-no" role="button">
            {{ $t("common.no") }}
          </label>
        </div>
        <div class="form-check" role="button">
          <input
            class="form-check-input"
            role="button"
            type="radio"
            name="subsidy_radio"
            id="has-subsidy-yes"
            :value="true"
            v-model="hasSubsidy"
          />
          <label class="form-check-label" for="has-subsidy-yes" role="button">
            {{ $t("common.yes") }}
          </label>
        </div>
      </div>
    </div>

    <!-- Subsidy Management Section -->
    <div v-if="hasSubsidy" class="row mb-2 gy-3 gx-0 mt-1">
      <label class="col-form-label mb-0 pb-0 mt-0">
        {{ $t("signup.add_generator_component.subsidy_title") }}
      </label>
      <div v-for="(_subsidy, index) in localGenerator.subsidies" :key="index">
        <SubsidyCardComponent
          v-model:subsidy="localGenerator.subsidies[index]"
          :extraData="false"
          :removeSubsidy="() => removeSubsidy(index)"
        />
      </div>
      <button class="btn btn-subtle-primary col-sm-11" @click="addSubsidy">
        <font-awesome-icon icon="plus" />
        {{ $t("signup.add_generator_component.add_subsidy") }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, ref, watch } from "vue";
import type { Account } from "@/types";
import { useI18n } from "vue-i18n";
import { getGeneratorFields } from "@/components/signup/lv/generator-fields";
import DynamicGeneratorField from "@/components/signup/lv/DynamicGeneratorField.vue";
import SubsidyCardComponent from "@/components/signup/lv/SubsidyCardComponent.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default defineComponent({
  name: "GeneratorReviewComponent",
  components: {
    DynamicGeneratorField,
    SubsidyCardComponent,
    FontAwesomeIcon,
  },
  props: {
    generatorIndex: {
      type: Number,
      required: true,
    },
    companyIndex: {
      type: Number,
      required: true,
    },
    accountData: {
      type: Object as () => Account,
      required: true,
    },
  },
  emits: ["handleUpdateGenerator"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const generator = computed(() => {
      return props.accountData.companies[props.companyIndex].generators[
        props.generatorIndex
      ];
    });
    const localGenerator = reactive({
      ...generator.value,
    });

    const generatorFields = getGeneratorFields(t).generatorFields;

    watch(
      localGenerator,
      (newValue) => {
        emit("handleUpdateGenerator", newValue);
      },
      { deep: true }
    );

    const hasSubsidy = ref(false);
    if (generator.value.subsidies && generator.value.subsidies.length > 0) {
      hasSubsidy.value = true;
    }

    // Initialize subsidies array if it doesn't exist
    if (!localGenerator.subsidies) {
      localGenerator.subsidies = [];
    }

    const addSubsidy = () => {
      localGenerator.subsidies.push({
        provider: "",
        decision_date: null,
        plan: null,
        amount: null,
      });
    };

    const removeSubsidy = (index: number) => {
      localGenerator.subsidies.splice(index, 1);
      if (localGenerator.subsidies.length === 0) {
        hasSubsidy.value = false;
      }
    };

    watch(hasSubsidy, (newValue) => {
      if (newValue) {
        if (localGenerator.subsidies.length === 0) {
          addSubsidy();
        }
      } else {
        localGenerator.subsidies = [];
      }
    });

    return {
      localGenerator,
      generatorFields,
      hasSubsidy,
      addSubsidy,
      removeSubsidy,
    };
  },
});
</script>

<style scoped>
.generator-review {
  .col-form-label {
    text-align: left;
    font-weight: bold;
    padding: 0;
  }
  .btn-outline-secondary {
    padding: 0.375rem 0.75rem;
  }
  .row {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 575.98px) {
  .generator-review {
    .col-form-label {
      margin-top: 1rem;
    }
  }
  #form-title {
    margin-top: 0.2rem;
  }
  h3 {
    font-size: 1.2rem;
  }
  .btn-subtle-danger {
    width: 100%;
  }
}
</style>
