<!-- client/src/views/SignUpView.vue -->

<template>
  <div class="signup-view">
    <div v-if="isAccountDataReady" class="container py-5 pt-3 pt-xl-5">
      <div v-if="isAccountDataReady">
        <EESignupFlow
          v-if="selectedCountry === 'EE'"
          :accountData="accountData"
          :companyIndex="companyIndex"
          :generatorIndex="generatorIndex"
          :companyIdQueryString="companyIdQueryString"
          :handleUpdateAccountData="handleUpdateAccountData"
          @handleUpdateCompany="handleUpdateCompany"
          @handleUpdateGenerator="handleUpdateGenerator"
          @createCompanyIfMissing="createCompanyIfMissing"
        />

        <LVSignupFlow
          v-if="selectedCountry === 'LV'"
          :accountData="accountData"
          :companyIndex="companyIndex"
          :generatorIndex="generatorIndex"
          :companyIdQueryString="companyIdQueryString"
          :handleUpdateAccountData="handleUpdateAccountData"
          @handleUpdateCompany="handleUpdateCompany"
          @handleUpdateGenerator="handleUpdateGenerator"
          @createCompanyIfMissing="createCompanyIfMissing"
          @createGeneratorIfMissing="createGeneratorIfMissing"
        />

        <LTSignupFlow
          v-if="selectedCountry === 'LT'"
          :accountData="accountData"
          :companyIndex="companyIndex"
          :generatorIndex="generatorIndex"
          :companyIdQueryString="companyIdQueryString"
          :handleUpdateAccountData="handleUpdateAccountData"
          @handleUpdateCompany="handleUpdateCompany"
          @handleUpdateGenerator="handleUpdateGenerator"
          @createCompanyIfMissing="createCompanyIfMissing"
          @removeEmptyCompanyIfExists="removeEmptyCompanyIfExists"
        />
        <NewCountrySignupFlow
          v-if="
            selectedCountry && !SUPPORTED_COUNTRIES.includes(selectedCountry)
          "
          :accountData="accountData"
          :companyIndex="companyIndex"
          :generatorIndex="generatorIndex"
          :companyIdQueryString="companyIdQueryString"
          :handleUpdateAccountData="handleUpdateAccountData"
          @handleUpdateCompany="handleUpdateCompany"
          @handleUpdateGenerator="handleUpdateGenerator"
          @createCompanyIfMissing="createCompanyIfMissing"
        />

        <EmptySignupFlow
          v-if="!selectedCountry"
          :accountData="accountData"
          :companyIndex="companyIndex"
          :generatorIndex="generatorIndex"
          :companyIdQueryString="companyIdQueryString"
          :handleUpdateAccountData="handleUpdateAccountData"
          @handleUpdateCompany="handleUpdateCompany"
          @handleUpdateGenerator="handleUpdateGenerator"
          @createCompanyIfMissing="createCompanyIfMissing"
        />
      </div>
      <p class="fs-9 text-body-tertiary signup-legal-section mt-3 text-center">
        {{ $t("signup.legal.byJoining") }}
        <a
          :href="countryLinks.membership_agreement"
          target="_blank"
          class="signup-link"
        >
          {{ $t("signup.legal.membershipAgreement") }}
        </a>
        <br class="d-none d-md-inline" />
        {{ $t("signup.legal.personalDataNote") }}
        <a
          :href="countryLinks.privacy_policy"
          target="_blank"
          class="signup-link"
        >
          {{ $t("signup.legal.privacyPolicy") }}
        </a>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from "vue";
import { useCompanyFinder } from "@/composables/useCompanyFinder";
import { useGeneratorFinder } from "@/composables/useGeneratorFinder";
import type { Account, Company } from "@/types";
import { fetchAccount } from "@/composables/account/fetchAccount";
import { watchEffectOnceAsync } from "@/composables/watchEffect";
import EESignupFlow from "@/components/signup/ee/EESignupFlow.vue";
import LVSignupFlow from "@/components/signup/lv/LVSignupFlow.vue";
import EmptySignupFlow from "@/components/signup/EmptySignupFlowComponent.vue";
import { useSelectedCountry } from "@/composables/useSelectedCountry";
import LTSignupFlow from "@/components/signup/lt/LTSignupFlow.vue";
import NewCountrySignupFlow from "@/components/signup/NewCountrySignupFlow.vue";
import { SUPPORTED_COUNTRIES } from "@/constants";

export default defineComponent({
  components: {
    NewCountrySignupFlow,
    EESignupFlow,
    LVSignupFlow,
    LTSignupFlow,
    EmptySignupFlow,
  },
  setup() {
    const { accountData } = fetchAccount();
    const createContainerForStartSigningFunction = ref(() => undefined);
    const { selectedCountry, ensureCountryChosen } =
      useSelectedCountry(accountData);

    const {
      companyIndex,
      companyIdQueryString,
      findCompanyIndexWithoutId,
      createCompanyIfMissing,
      removeEmptyCompanyIfExists,
    } = useCompanyFinder(accountData);
    const company = computed(() => {
      return accountData.companies[companyIndex.value];
    });
    const {
      generatorIndex,
      nullableSelectedGenerator,
      createGeneratorIfMissing,
    } = useGeneratorFinder(accountData);

    const isAccountDataReady = computed(() => {
      return accountData && accountData.email; // or any other field that indicates data is loaded
    });

    type CountryLinks = {
      [key: string]: {
        membership_agreement: string;
        privacy_policy: string;
      };
    };

    const countryLinks = computed(() => {
      const links: CountryLinks = {
        EE: {
          membership_agreement:
            "https://soldera-public-statics.s3.eu-west-1.amazonaws.com/example-contracts/EE+Soldera+Membership+Agreement+Example+.pdf",
          privacy_policy:
            "https://www.soldera.org/compliance/privaatsuspoliitika",
        },
        LV: {
          membership_agreement:
            "https://soldera-public-statics.s3.eu-west-1.amazonaws.com/example-contracts/LV+Soldera+Membership+Agreement+Example+.pdf",
          privacy_policy:
            "https://www.soldera.org/lv/compliance/privatuma-politika",
        },
        LT: {
          membership_agreement:
            "https://soldera-public-statics.s3.eu-west-1.amazonaws.com/example-contracts/LT+Soldera+Membership+Agreement+Example.pdf",
          privacy_policy:
            "https://www.soldera.org/lt/compliance/privatumo-politika",
        },
        EN: {
          membership_agreement:
            "https://soldera-public-statics.s3.eu-west-1.amazonaws.com/example-contracts/EN+Soldera+Membership+Agreement+Example.pdf",
          privacy_policy:
            "https://www.soldera.org/en/compliance/privacy-policy",
        },
      };
      if (selectedCountry.value && links[selectedCountry.value]) {
        return links[selectedCountry.value];
      } else {
        return links["EN"];
      }
    });

    const handleUpdateAccountData = (updatedAccountData: Account) => {
      Object.assign(accountData, updatedAccountData);
    };
    const handleUpdateCompany = (updatedCompany: Company) => {
      Object.assign(accountData.companies[companyIndex.value], updatedCompany);
    };
    const handleUpdateGenerator = (updatedGenerator: Generator) => {
      Object.assign(
        accountData.companies[companyIndex.value].generators[
          generatorIndex.value
        ],
        updatedGenerator
      );
    };

    onMounted(async () => {
      if (!isAccountDataReady.value) {
        await watchEffectOnceAsync(() => isAccountDataReady.value);

        ensureCountryChosen();
      }
    });

    return {
      countryLinks,
      companyIndex,
      companyIdQueryString,
      company,
      generatorIndex,
      nullableSelectedGenerator,
      accountData,
      handleUpdateAccountData,
      createContainerForStartSigningFunction,
      isAccountDataReady,
      findCompanyIndexWithoutId,
      handleUpdateCompany,
      handleUpdateGenerator,
      createCompanyIfMissing,
      selectedCountry,
      removeEmptyCompanyIfExists,
      SUPPORTED_COUNTRIES,
      createGeneratorIfMissing,
    };
  },
});
</script>

<style scoped lang="scss">
.signup-view {
  overflow-y: auto;
  // Custom container styles
  .container {
    max-width: 800px;
  }
}

.signup-legal-section {
  a.signup-link {
    color: rgb(var(--phoenix-primary-light-rgb));
    font-weight: 500;
    &:hover {
      color: rgb(var(--phoenix-link-color-rgb));
    }
  }
}
</style>
