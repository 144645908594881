<!-- client/src/components/signup/ContactReviewDisplayComponent.vue -->

<template>
  <div class="contact-review card p-4 mb-3" novalidate>
    <div v-if="accountFields.length > 0">
      <h3 class="mb-1 mb-sm-4">
        {{ $t("signup.contact_review_component.signer_title") }}
      </h3>
      <!-- Account Data Form -->
      <div class="row mb-2 g-2" v-for="field in accountFields" :key="field.id">
        <label :for="field.id" class="col-sm-3 mt-sm-3 col-form-label">{{
          field.label
        }}</label>
        <div class="col-sm-8 col-10">
          <input
            :type="field.type"
            :id="field.id"
            class="form-control"
            :class="{
              'is-invalid': v$.accountData[field.model].$error,
            }"
            :disabled="!field.editable"
            :value="accountData[field.model]"
            @input="updateAccountField(field.model, $event)"
            @blur="v$.accountData[field.model].$touch"
          />
          <div
            class="invalid-feedback"
            v-for="error of v$.accountData[field.model].$errors"
            :key="error.$uid"
          >
            {{ error.$message }}
          </div>
        </div>
        <div class="col-sm-1 col-2">
          <button
            class="btn btn-outline-secondary h-100"
            v-if="!field.editable"
            @click="enableEditing(field)"
          >
            <font-awesome-icon icon="pencil-alt" />
          </button>
        </div>
      </div>
    </div>

    <div v-if="shouldShowCompanyFields">
      <h3 class="mb-1 mb-sm-2 mt-4">
        {{
          company_section_title
            ? company_section_title
            : $t("signup.contact_review_component.generator_owner_title")
        }}
      </h3>
      <p class="mb-2 mb-sm-4">
        {{
          company_section_description
            ? company_section_description
            : $t("signup.contact_review_component.generator_owner_subtext")
        }}
      </p>
    </div>

    <div v-if="enableAutofill || isExistingCompanyWithDocuments">
      <CompanySelectDropdown
        :existingCompanies="existingCompanies"
        :selectedCompany="company"
        @selectExistingCompany="$emit('selectExistingCompany', $event)"
        @resetCompanySelection="$emit('resetCompanySelection', $event)"
      />
      <hr />
    </div>

    <!-- Company Data Form -->
    <div v-for="field in companyFields" :key="field.id">
      <div
        class="row mb-2 g-2"
        v-if="field.model === 'address' || shouldShowCompanyFields"
      >
        <label :for="field.id" class="col-sm-3 mt-sm-3 col-form-label">{{
          field.label
        }}</label>
        <div class="col-sm-8 col-10">
          <input
            :type="field.type"
            :id="field.id"
            class="form-control"
            :class="{ 'is-invalid': v$.company[field.model].$error }"
            :disabled="
              !field.editable ||
              (isExistingCompanyWithDocuments && !existingDataOverride)
            "
            :value="company[field.model]"
            @input="updateCompanyField(field.model, $event)"
            @blur="v$.company[field.model].$touch"
          />
          <div
            class="invalid-feedback"
            v-for="error of v$.company[field.model].$errors"
            :key="error.$uid"
          >
            {{ error.$message }}
          </div>
        </div>
        <div class="col-sm-1 col-2">
          <button
            class="btn btn-outline-secondary h-100"
            v-if="!field.editable"
            @click="enableEditing(field)"
          >
            <font-awesome-icon icon="pencil-alt" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, toRefs, reactive, computed } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import type { Account, Company, ContactFieldType } from "@/types";
import { useVuelidate } from "@vuelidate/core";
import { useCompanyFinder } from "@/composables/useCompanyFinder";
import CompanySelectDropdown from "./CompanySelectDropdown.vue";

export default defineComponent({
  name: "ContactReviewDisplayComponent",
  components: {
    FontAwesomeIcon,
    CompanySelectDropdown,
  },
  props: {
    accountData: {
      type: Object as () => Account,
      required: true,
    },
    companyIndex: {
      type: Number,
      required: false,
    },
    editableAccountFields: {
      type: Array as () => ContactFieldType[],
      required: true,
    },
    editableCompanyFields: {
      type: Array as () => ContactFieldType[],
      required: true,
    },
    shouldShowCompanyFields: {
      type: Boolean,
      required: true,
    },
    enableAutofill: {
      type: Boolean,
      default: false,
    },
    existingCompanies: {
      type: Array as () => Company[],
      default: () => [],
    },
    company_section_title: {
      type: String,
      default: "",
    },
    company_section_description: {
      type: String,
      default: "",
    },
    existingDataOverride: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "updateAccountData",
    "handleUpdateCompany",
    "selectExistingCompany",
    "resetCompanySelection",
  ],
  setup(props, { emit }) {
    const { selectedCompany } = useCompanyFinder(props.accountData);
    const company = computed(() => selectedCompany.value);
    const { accountData } = toRefs(props);

    const accountFields: ContactFieldType[] = reactive(
      props.editableAccountFields
    );
    const companyFields: ContactFieldType[] = reactive(
      props.editableCompanyFields
    );

    const enableEditing = (field: { ["editable"]: boolean }) => {
      field.editable = true;
    };

    // FORM VALIDATION SECTION

    const rules = computed(() => {
      const validationRules = { accountData: {}, company: {} } as any;

      // Rules for account fields
      accountFields.forEach((field) => {
        validationRules.accountData[field.model] = field.validators;
      });

      // Rules for company fields
      companyFields.forEach((field) => {
        validationRules.company[field.model] = field.validators;
      });

      return validationRules;
    });

    const v$ = useVuelidate(rules, { accountData, company });

    const updateAccountField = async (field: string | number, event: Event) => {
      const updatedValue = {
        ...props.accountData,
        [field]: (event.target as HTMLInputElement).value,
      } as Account;
      // Emit an event to update the parent's subsidy object
      emit("updateAccountData", updatedValue);
    };
    const updateCompanyField = async (field: string | number, event: Event) => {
      const updatedValue = {
        ...company.value,
        [field]: (event.target as HTMLInputElement).value,
      } as Company;
      // Emit an event to update the parent's subsidy object
      emit("handleUpdateCompany", updatedValue);
    };

    const isExistingCompanyWithDocuments = computed(() => {
      return (
        company.value &&
        company.value.id !== undefined &&
        company.value.documents &&
        company.value.documents.length > 0
      );
    });

    return {
      accountFields,
      companyFields,
      enableEditing,
      updateAccountField,
      updateCompanyField,
      isExistingCompanyWithDocuments,
      company,
      v$,
    };
  },
});
</script>

<style scoped>
.contact-review {
  .col-form-label {
    text-align: left;
    font-weight: bold;
    padding: 0;
  }
  .btn-outline-secondary {
    padding: 0.375rem 0.75rem;
  }
  .row {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 575.98px) {
  .contact-review {
    .col-form-label {
      margin-top: 1rem;
    }
  }
  h3 {
    font-size: 1.2rem;
  }
}
</style>
