import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, withDirectives as _withDirectives, vModelText as _vModelText, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22ecd3b1"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "field-container"
}
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "field-description"
}
const _hoisted_4 = ["type", "id"]
const _hoisted_5 = ["id"]
const _hoisted_6 = ["id"]
const _hoisted_7 = ["value"]
const _hoisted_8 = ["id"]
const _hoisted_9 = { value: true }
const _hoisted_10 = { value: false }
const _hoisted_11 = {
  key: 5,
  class: "invalid-feedback"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.shouldShow)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("label", {
          for: _ctx.field.title,
          class: "field-label"
        }, _toDisplayString(_ctx.$t(`${_ctx.translationPrefix}.generator_review.${_ctx.field.title}.title`)), 9, _hoisted_2),
        (_ctx.hasDescription)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t(`${_ctx.translationPrefix}.generator_review.${_ctx.field.title}.description`)), 1))
          : _createCommentVNode("", true),
        (['string', 'number'].includes(_ctx.field.type))
          ? _withDirectives((_openBlock(), _createElementBlock("input", {
              key: 1,
              type: _ctx.field.type === 'number' ? 'number' : 'text',
              id: _ctx.field.title,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
              class: _normalizeClass(["form-control", { 'is-invalid': _ctx.v$.localValue.$error }]),
              onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleInput($event))),
              onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.v$.localValue.$touch && _ctx.v$.localValue.$touch(...args)))
            }, null, 42, _hoisted_4)), [
              [_vModelDynamic, _ctx.localValue]
            ])
          : (_ctx.field.type === 'date')
            ? _withDirectives((_openBlock(), _createElementBlock("input", {
                key: 2,
                type: "date",
                id: _ctx.field.title,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localValue) = $event)),
                class: _normalizeClass(["form-control", { 'is-invalid': _ctx.v$.localValue.$error }]),
                onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleInput($event))),
                onBlur: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.v$.localValue.$touch && _ctx.v$.localValue.$touch(...args)))
              }, null, 42, _hoisted_5)), [
                [_vModelText, _ctx.localValue]
              ])
            : (_ctx.field.type === 'dropdown')
              ? _withDirectives((_openBlock(), _createElementBlock("select", {
                  key: 3,
                  id: _ctx.field.title,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localValue) = $event)),
                  class: _normalizeClass(["form-select", { 'is-invalid': _ctx.v$.localValue.$error }]),
                  onChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.handleInput($event))),
                  onBlur: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.v$.localValue.$touch && _ctx.v$.localValue.$touch(...args)))
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.field.options, (option) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: option.label,
                      value: option.value
                    }, _toDisplayString(_ctx.$t(
            `${_ctx.translationPrefix}.generator_review.${_ctx.field.title}.options.${option.label}`
          )), 9, _hoisted_7))
                  }), 128))
                ], 42, _hoisted_6)), [
                  [_vModelSelect, _ctx.localValue]
                ])
              : (_ctx.field.type === 'boolean')
                ? _withDirectives((_openBlock(), _createElementBlock("select", {
                    key: 4,
                    id: _ctx.field.title,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.localValue) = $event)),
                    class: _normalizeClass(["form-select", { 'is-invalid': _ctx.v$.localValue.$error }]),
                    onChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.handleInput($event))),
                    onBlur: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.v$.localValue.$touch && _ctx.v$.localValue.$touch(...args)))
                  }, [
                    _createElementVNode("option", _hoisted_9, _toDisplayString(_ctx.$t("common.yes")), 1),
                    _createElementVNode("option", _hoisted_10, _toDisplayString(_ctx.$t("common.no")), 1)
                  ], 42, _hoisted_8)), [
                    [_vModelSelect, _ctx.localValue]
                  ])
                : _createCommentVNode("", true),
        (_ctx.hasError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.errorMessage), 1))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}