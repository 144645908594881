import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContactReviewDisplayComponent = _resolveComponent("ContactReviewDisplayComponent")!

  return (_openBlock(), _createBlock(_component_ContactReviewDisplayComponent, {
    accountData: _ctx.accountData,
    companyIndex: _ctx.companyIndex,
    editableAccountFields: _ctx.accountFields,
    editableCompanyFields: _ctx.companyFields,
    shouldShowCompanyFields: _ctx.shouldShowCompanyFields,
    onUpdateAccountData: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('updateAccountData', $event))),
    onHandleUpdateCompany: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('handleUpdateCompany', $event))),
    onSelectExistingCompany: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('selectExistingCompany', $event))),
    onResetCompanySelection: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('resetCompanySelection')))
  }, null, 8, ["accountData", "companyIndex", "editableAccountFields", "editableCompanyFields", "shouldShowCompanyFields"]))
}