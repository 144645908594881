<template>
  <div class="multiple-upload-checklist p-4 card pb-0" v-if="accountData.id">
    <h3 class="mb-3">{{ $t("signup.multiple_upload.title") }}</h3>
    <p class="mb-4 mb-sm-5">
      {{ $t("signup.multiple_upload.description") }}
    </p>

    <div v-for="item in uploadItems" :key="item.fileKey">
      <UploadChecklistItemComponent
        :uploadTitle="
          getTranslation(item.title, `signup.multiple_upload.${item.fileKey}`)
        "
        :supportedFormats="
          getTranslation(item.supportedFormats, 'common.any_file_format')
        "
        :uploadUrl="item.uploadUrl || defaultUploadUrl"
        :accountId="accountData.id"
        :fileUrl="findFileUrl(item.fileKey).value"
        :queryParams="getParams(item.fileKey).value"
        :uploadErrorMessage="
          getTranslation(item.uploadErrorMessage, 'common.invalid_file_error')
        "
        @uploadComplete="$emit('uploadComplete', $event)"
        @errorOccured="$emit('errorOccured', $event)"
      />

      <BasicInfoCardComponent
        :buttonText="$t('common.read_more_button')"
        :titleText="
          getTranslation(
            item.infoTitle,
            'signup.multiple_upload.where_to_find_this_document'
          )
        "
        class="mb-6"
      >
        <p
          class="mt-2 mb-2"
          v-html="
            getTranslation(
              item.infoContent,
              `signup.multiple_upload.${item.fileKey}_where_to_find`
            )
          "
        ></p>
      </BasicInfoCardComponent>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from "vue";
import type { Account } from "@/types";
import UploadChecklistItemComponent from "@/components/common/UploadChecklistItemComponent.vue";
import BasicInfoCardComponent from "@/components/common/BasicInfoCardComponent.vue";
import { useCompanyFinder } from "@/composables/useCompanyFinder";
import { useGeneratorFinder } from "@/composables/useGeneratorFinder";
import { useI18n } from "vue-i18n";

export interface UploadItem {
  fileKey: string;
  title?: string; // Translation key
  supportedFormats?: string; // Translation key
  uploadUrl?: string; // Direct value
  infoTitle?: string; // Translation key
  infoContent?: string; // Translation key
  uploadErrorMessage?: string; // Translation key
}

export default defineComponent({
  name: "MultipleUploadComponent",
  components: {
    UploadChecklistItemComponent,
    BasicInfoCardComponent,
  },
  props: {
    accountData: {
      type: Object as PropType<Account>,
      required: true,
    },
    uploadItems: {
      type: Array as PropType<UploadItem[]>,
      required: true,
    },
    defaultUploadUrl: {
      type: String,
      required: true,
    },
  },
  emits: ["uploadComplete", "errorOccured"],
  setup(props) {
    const { t } = useI18n();
    const { companyIndex } = useCompanyFinder(props.accountData);
    const selectedCompany = computed(
      () => props.accountData.companies[companyIndex.value]
    );
    const { nullableSelectedGenerator } = useGeneratorFinder(props.accountData);

    const getTranslation = (key?: string, fallbackKey?: string) => {
      if (key) {
        return t(key);
      }
      return fallbackKey ? t(fallbackKey) : "";
    };

    const getParams = (fileKey: string) => {
      return computed(() => {
        const params = new Map<string, any>([
          ["file_key", fileKey],
          ["company_id", selectedCompany?.value?.id],
          ["account_id", props.accountData.id],
        ]);
        if (nullableSelectedGenerator.value.generator) {
          params.set(
            "generator_id",
            nullableSelectedGenerator.value.generator.id
          );
        }
        return params;
      });
    };

    const findFileUrl = (fileKey: string) => {
      return computed(() => {
        if (nullableSelectedGenerator.value.generator?.files?.[fileKey]) {
          return nullableSelectedGenerator.value.generator.files[fileKey];
        }
        return "";
      });
    };

    return {
      getParams,
      findFileUrl,
      getTranslation,
    };
  },
});
</script>
