<!-- client/src/components/signup/UserTypeSelectionComponent.vue -->

<template>
  <div class="card">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card-body">
          <div class="p-6 p-md-5" v-if="!userType">
            <h2 class="card-title text-center mb-4">
              {{ $t("signup.user_type.select_title") }}
            </h2>
            <p class="text-center mb-5">
              {{ $t("signup.user_type.select_description") }}
            </p>

            <div class="d-flex flex-column gap-4">
              <div class="option-card">
                <button
                  class="btn btn-lg btn-outline-primary w-100 mb-2"
                  @click="selectUserType('company')"
                >
                  {{ $t("signup.user_type.company.button") }}
                </button>
                <p class="text-muted small">
                  {{ $t("signup.user_type.company.description") }}
                </p>
              </div>
              <div class="option-card">
                <button
                  class="btn btn-lg btn-outline-primary w-100 mb-2"
                  @click="selectUserType('private')"
                >
                  {{ $t("signup.user_type.private.button") }}
                </button>
                <p class="text-muted small">
                  {{ $t("signup.user_type.private.description") }}
                </p>
              </div>
            </div>
          </div>
          <div
            v-else
            class="d-flex justify-content-center flex-column flex-md-row text-center"
          >
            <span>{{ $t("signup.user_type.selection_prefix") }} </span>
            <span v-if="userType === 'private'" class="px-1">{{
              $t("signup.user_type.selected_private")
            }}</span>
            <span v-else class="px-1">{{
              $t("signup.user_type.selected_company")
            }}</span>
            <button class="btn btn-link btn-sm m-0 p-1">
              <FontAwesomeIcon icon="rotate-left" @click="resetUserType" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "vue";
import useVuelidate from "@vuelidate/core";
import { useI18n } from "vue-i18n";
import { useValidators } from "@/composables/useValidators";

export default defineComponent({
  name: "UserTypeSelectionComponent",

  props: {
    userType: {
      type: String as PropType<"private" | "company" | undefined>,
      required: true,
    },
    handleUpdateUserType: {
      type: Function as PropType<
        (userType: "private" | "company" | undefined) => void
      >,
      required: true,
    },
  },

  setup(props) {
    const selectUserType = async (type: "private" | "company") => {
      props.handleUpdateUserType(type);
    };

    const resetUserType = async () => {
      props.handleUpdateUserType(undefined);
    };

    // Form validation for hasGenerator
    const { t } = useI18n();
    const { required } = useValidators(t);
    const state = computed(() => ({
      userType: props.userType,
    }));

    const rules = {
      userType: { required },
    };

    const v$ = useVuelidate(rules, state);

    return {
      selectUserType,
      resetUserType,
      v$,
    };
  },
});
</script>
