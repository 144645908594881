// client/src/composables/useStepFunctions.ts

import { WritableComputedRef, Ref, computed } from "vue";

export interface StepConfig {
  step_name: number;
  title: string;
  titleKey: string;
  apiEndpoint?: string;
  preStepCallback?: (config: StepConfig) => Promise<boolean>;
  postStepCallback?: (config: StepConfig) => Promise<boolean>;
}

export function useStepFunctions(
  currentFlowSteps: Ref<StepConfig[]>,
  stepNumber: WritableComputedRef<number>
) {
  const shownStep = computed(() => {
    const stepIndex = stepNumber.value - 1;
    return currentFlowSteps.value[stepIndex] || currentFlowSteps.value[0];
  });

  const invokePreStepCallback = async (): Promise<boolean> => {
    if (shownStep.value.preStepCallback) {
      return shownStep.value.preStepCallback(shownStep.value);
    }
    return true;
  };

  const setStep = async (targetStepNumber: number) => {
    const currentStep = shownStep.value;
    const stepIndex = targetStepNumber - 1;
    const targetStep = currentFlowSteps.value[stepIndex];
    const goingBack = targetStepNumber < stepNumber.value;

    if (!targetStep) return;

    // Check post-step callback for current step
    // We allow going back if the post-step callback fails
    if (
      currentStep.postStepCallback &&
      !(await currentStep.postStepCallback(currentStep)) &&
      !goingBack
    ) {
      return;
    }

    // Check pre-step callback for target step
    if (
      targetStep.preStepCallback &&
      !(await targetStep.preStepCallback(targetStep))
    ) {
      return;
    }

    // If all callbacks pass, update the step
    stepNumber.value = targetStepNumber;
  };

  const navCheck = (step: number) =>
    computed(() => {
      if (step === 1) return true;
      return stepNumber.value - step >= -1;
    });

  const navSteps = computed(() =>
    currentFlowSteps.value.map((stepConfig, index) => ({
      title: stepConfig.title,
      isAvailable: navCheck(index + 1).value,
    }))
  );

  return {
    shownStep,
    navSteps,
    setStep,
    navCheck,
    invokePreStepCallback,
  };
}
