import { useRoute, useRouter } from "vue-router";
import { computed, WritableComputedRef } from "vue";

type QueryValue = string | number | boolean | undefined;
type QueryValueGetter<T> = (value: string | undefined) => T;

export function useQueryManager() {
  const route = useRoute();
  const router = useRouter();

  const updateQuery = async (updates: Record<string, string | undefined>) => {
    const newQuery = {
      ...route.query,
      ...updates,
    };

    await router.push({
      name: route.name as string,
      query: newQuery,
    });
  };

  const getQueryValue = <T extends QueryValue>(
    key: string,
    parser: QueryValueGetter<T>
  ): WritableComputedRef<T> => {
    return computed({
      get: () => parser(route.query[key]?.toString()),
      set: (value: T) =>
        updateQuery({
          [key]: value === undefined ? undefined : value.toString(),
        }),
    });
  };

  const parsers = {
    boolean: (value?: string) => value === "true",
    number: (value?: string) => (value ? parseInt(value, 10) : undefined),
    string: (value?: string) => value,
    enum:
      <T extends string>(values: T[]) =>
      (value?: string): T | undefined =>
        value && values.includes(value as T) ? (value as T) : undefined,
    userType: (value?: string) => {
      if (value === "private" || value === "company") {
        return value;
      }
      return undefined;
    },
  };

  return {
    updateQuery,
    getQueryValue,
    parsers,
  };
}
