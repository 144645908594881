// src/composables/useGeneratorFinder.ts

import { computed } from "vue";
import { useRoute } from "vue-router";
import type { Account, Generator } from "@/types";
import { useCompanyFinder } from "./useCompanyFinder";

export function useGeneratorFinder(accountData: Account) {
  const route = useRoute();
  const { companyIndex } = useCompanyFinder(accountData);

  const generatorID = computed(() => {
    return parseInt(route.query.generator_id as string);
  });

  const generatorIndex = computed(() => {
    if (companyIndex.value != -1 && generatorID.value) {
      return accountData.companies[companyIndex.value].generators.findIndex(
        (generator: Generator) => generator.id === generatorID.value
      );
    } else {
      return findEmptyGeneratorIndex.value;
    }
  });

  const nullableSelectedGenerator = computed(() => {
    if (
      !accountData ||
      !accountData.companies ||
      Number.isNaN(generatorID.value)
    ) {
      return { generator: null };
    }
    return selectedGenerator.value;
  });
  const findEmptyGeneratorIndex = computed(() => {
    if (companyIndex.value === -1 || !accountData.companies) {
      return -1;
    }
    return accountData.companies[companyIndex.value].generators.findIndex(
      (generator: Generator) =>
        generator.id === undefined || generator.id === null
    );
  });

  const createGeneratorIfMissing = async () => {
    if (companyIndex.value === -1 || !accountData.companies) {
      throw new Error("No company found to add generator to");
    }
    if (findEmptyGeneratorIndex.value === -1) {
      const newGenerator: Generator = {
        id: undefined,
        company_id: accountData.companies[companyIndex.value].id,
        grid_contract: "",
        solar_panel_model: null,
        has_subsidy: null,
        has_registered: false,
        device_eic: "",
        device_address: "",
        device_power: 0,
        device_name: "",
        device_country: accountData.companies[companyIndex.value].country,
        device_technology: "",
        grid_contract_start_date: null,
        license_number: "",
        subsidies: [],
        battery: null,
        ppa: null,
      };

      accountData.companies[companyIndex.value].generators.push(newGenerator);
    }
  };

  const selectedGenerator = computed(() => {
    if (
      accountData &&
      accountData.companies &&
      accountData.companies[companyIndex.value]
    ) {
      return {
        generator:
          accountData.companies[companyIndex.value].generators[
            generatorIndex.value
          ],
      };
    }
    throw new Error("Generator not found");
  });

  return {
    selectedGenerator,
    nullableSelectedGenerator,
    generatorID,
    generatorIndex,
    createGeneratorIfMissing,
  };
}
