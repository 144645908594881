<template>
  <div class="container py-5 overflow-y-auto">
    <h1 class="mb-4">Forwards Service Available Volumes</h1>
    <div class="mb-4 d-flex align-items-center gap-3">
      <div class="form-check">
        <input
          type="checkbox"
          class="form-check-input"
          id="contangoCheckbox"
          v-model="marketInContango"
        />
        <label class="form-check-label" for="contangoCheckbox"
          >Market is currently in contango</label
        >
      </div>
      <div class="dropdown">
        <button
          class="btn btn-secondary dropdown-toggle"
          type="button"
          id="yearDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {{ viewYear }} Production
        </button>
        <ul class="dropdown-menu" aria-labelledby="yearDropdown">
          <li v-for="year in availableYears" :key="year">
            <a
              class="dropdown-item"
              href="#"
              :class="{ active: year === viewYear }"
              @click.prevent="viewYear = year"
            >
              {{ year }} Production
            </a>
          </li>
        </ul>
      </div>
      <button class="btn btn-subtle-secondary" @click="handleButtonClick">
        <FontAwesomeIcon icon="sync" />
      </button>
    </div>
    <table class="table table-bordered mt-4">
      <thead>
        <tr>
          <th>Name</th>
          <th v-for="quarter in 4" :key="quarter">Q{{ quarter }} Count</th>
          <th>Skip Sale If Not Contango and 20% above spot</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in data" :key="item.id">
          <td>{{ item.company_name }}</td>
          <td v-for="quarter in 4" :key="quarter">
            {{ item[`q${quarter}_available`] }}
          </td>
          <td>{{ item.skip_sale_if_not_contango ? "Yes" : "No" }}</td>
        </tr>
        <tr class="fw-bold">
          <td>Sum</td>
          <td v-for="quarter in 4" :key="quarter">
            {{ getQuarterTotal(quarter) }}
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, watch } from "vue";
import { goSolidApi } from "@/composables/useGoSolidApi";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default defineComponent({
  name: "ForwardsInstructionsSummaryView",
  setup() {
    const data = ref<any[]>([]);
    const marketInContango = ref<boolean>(true);
    const currentYear = new Date().getFullYear();
    const viewYear = ref(currentYear + 1);

    const availableYears = computed(() => [currentYear + 1, currentYear + 2]);

    const fetchData = async () => {
      try {
        const response = await goSolidApi.get(
          `/api/admin/forward-summary/${viewYear.value}`
        );
        if (response.status === 200 && response.data) {
          data.value = response.data;
        } else {
          throw new Error("Unexpected response from API");
        }
      } catch (error: any) {
        console.error("Error fetching data:", error);
      }
    };

    const getQuarterTotal = (quarter: number) => {
      return data.value.reduce(
        (sum, item) =>
          marketInContango.value || !item.skip_sale_if_not_contango
            ? sum + item[`q${quarter}_available`]
            : sum,
        0
      );
    };

    watch(
      viewYear,
      () => {
        fetchData();
      },
      { immediate: true }
    );

    return {
      handleButtonClick: fetchData,
      data,
      marketInContango,
      getQuarterTotal,
      viewYear,
      availableYears,
    };
  },
});
</script>
