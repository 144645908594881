<template>
  <ContactReviewDisplayComponent
    :accountData="accountData"
    :companyIndex="companyIndex"
    :editableAccountFields="accountFields"
    :editableCompanyFields="companyFields"
    :shouldShowCompanyFields="shouldShowCompanyFields"
    @updateAccountData="$emit('updateAccountData', $event)"
    @handleUpdateCompany="$emit('handleUpdateCompany', $event)"
    @selectExistingCompany="$emit('selectExistingCompany', $event)"
    @resetCompanySelection="$emit('resetCompanySelection')"
  />
</template>

<script lang="ts">
import { defineComponent, computed, watch } from "vue";
import type { Account, ContactFieldType } from "@/types";
import { useValidators } from "@/composables/useValidators";
import { useI18n } from "vue-i18n";
import ContactReviewDisplayComponent from "@/components/signup/ContactReviewDisplayComponent.vue";

export default defineComponent({
  name: "ContactReviewConfigurationComponent",
  components: {
    ContactReviewDisplayComponent,
  },
  props: {
    accountData: {
      type: Object as () => Account,
      required: true,
    },
    companyIndex: {
      type: Number,
      required: true,
    },
    shouldShowCompanyFields: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    "updateAccountData",
    "handleUpdateCompany",
    "selectExistingCompany",
    "resetCompanySelection",
  ],
  setup(props, { emit }) {
    const { t } = useI18n();
    const {
      required,
      lithuanianCompanyNumber,
      lithuanianPersonalID,
      personalName,
      phoneNumber,
    } = useValidators(t);

    const accountFields = computed(() => {
      const fields: ContactFieldType[] = [
        {
          label: t("account.name"),
          id: "account-name",
          model: "name",
          type: "text",
          editable: true,
          validators: { required, personalName },
        },
      ];

      if (props.shouldShowCompanyFields) {
        // Company registration - show phone number
        fields.push({
          label: t("account.phone_number"),
          id: "account-phone-number",
          model: "phone_number",
          type: "tel",
          editable: true,
          validators: { required, phoneNumber },
        });
      } else {
        // Private individual - show personal ID
        fields.push(
          {
            label: t("account.registry_code"),
            id: "account-registry-code",
            model: "registry_code",
            type: "text",
            editable: true,
            validators: { required, lithuanianPersonalID },
          },
          {
            label: t("account.phone_number"),
            id: "account-phone-number",
            model: "phone_number",
            type: "tel",
            editable: true,
            validators: { required, phoneNumber },
          }
        );
      }

      return fields;
    });

    const companyFields = computed(() => {
      const fields: ContactFieldType[] = [];

      if (props.shouldShowCompanyFields) {
        // Company view - show all fields
        fields.push(
          {
            label: t("company.name"),
            id: "company-name",
            model: "name",
            type: "text",
            editable: true,
            validators: { required },
          },
          {
            label: t("company.registry_code"),
            id: "company-registry-code",
            model: "registry_code",
            type: "text",
            editable: true,
            validators: [required, lithuanianCompanyNumber],
          }
        );
      }

      // Always show address
      fields.push({
        label: t("company.address"),
        id: "company-address",
        model: "address",
        type: "text",
        editable: true,
        validators: { required },
      });

      return fields;
    });

    if (!props.shouldShowCompanyFields && props.companyIndex !== -1) {
      watch(
        () => props.accountData.name,
        (newName) => {
          if (newName && props.companyIndex !== -1) {
            emit("handleUpdateCompany", {
              ...props.accountData.companies[props.companyIndex],
              name: newName,
            });
          }
        },
        { immediate: true }
      );

      watch(
        () => props.accountData.registry_code,
        (newCode) => {
          if (newCode && props.companyIndex !== -1) {
            emit("handleUpdateCompany", {
              ...props.accountData.companies[props.companyIndex],
              registry_code: newCode,
            });
          }
        },
        { immediate: true }
      );
    }

    return {
      accountFields,
      companyFields,
    };
  },
});
</script>
