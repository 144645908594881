<template>
  <div class="card mb-3">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card-body p-6 p-md-5">
          <!-- Company Selection Section -->
          <div v-if="existingCompanies && existingCompanies.length > 0">
            <h3 class="text-center mb-4 fs-5">
              {{
                $t(
                  `signup.${countryLocale}.authorization.existing_account_title`
                )
              }}
            </h3>
            <CompanySelectDropdown
              :existingCompanies="existingCompanies"
              @selectExistingCompany="onSelectExistingCompany"
              @resetCompanySelection="onResetCompanySelection"
              class="mb-3"
            />
            <p class="text-muted small">
              {{
                $t(
                  `signup.${countryLocale}.authorization.alternative_instructions`
                )
              }}
            </p>
            <hr class="my-5" />
          </div>

          <!-- Original Onboarding Options -->
          <h2 class="card-title text-center mb-4">
            {{ $t(`signup.${countryLocale}.authorization.title`) }}
          </h2>
          <p class="text-center mb-5">
            {{ $t(`signup.${countryLocale}.authorization.description`) }}
          </p>

          <div class="d-flex flex-column gap-4">
            <div class="option-card">
              <button
                class="btn btn-lg btn-outline-primary w-100 mb-2"
                @click="addAuthorizationAgreementFlow()"
              >
                {{
                  $t(`signup.${countryLocale}.authorization.add_authorization`)
                }}
              </button>
              <p class="text-muted small">
                {{
                  $t(
                    `signup.${countryLocale}.authorization.add_authorization_description`
                  )
                }}
              </p>
            </div>

            <div class="option-card">
              <button
                class="btn btn-lg btn-outline-primary w-100 mb-2"
                @click="addNewDeviceFlow()"
              >
                {{
                  $t(`signup.${countryLocale}.authorization.create_new_account`)
                }}
              </button>
              <p class="text-muted small">
                {{
                  $t(
                    `signup.${countryLocale}.authorization.create_new_account_description`
                  )
                }}
              </p>
            </div>
          </div>

          <p class="text-center mt-4 small text-muted">
            {{ $t(`signup.${countryLocale}.authorization.not_sure`) }}
            <a
              class="btn btn-link btn-sm p-0 fw-normal"
              @click="openHelpCrunch"
            >
              {{ $t(`signup.${countryLocale}.authorization.contact_support`) }}
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { useHelpCrunch } from "@/composables/useHelpCrunch";
import CompanySelectDropdown from "@/components/signup/CompanySelectDropdown.vue";
import type { Company } from "@/types";

export default defineComponent({
  components: {
    CompanySelectDropdown,
  },
  props: {
    addAuthorizationAgreementFlow: {
      type: Function as PropType<() => void>,
      required: true,
    },
    addNewDeviceFlow: {
      type: Function as PropType<() => void>,
      required: true,
    },
    existingCompanies: {
      type: Array as PropType<Company[]>,
      default: () => [],
    },
    countryLocale: {
      type: String as PropType<string>,
      required: true,
    },
  },
  emits: ["selectExistingCompany", "resetCompanySelection"],
  setup(_, { emit }) {
    const { openHelpCrunch } = useHelpCrunch();

    const onSelectExistingCompany = (companyId: number) => {
      emit("selectExistingCompany", companyId);
    };

    const onResetCompanySelection = () => {
      emit("resetCompanySelection");
    };

    return {
      openHelpCrunch,
      onSelectExistingCompany,
      onResetCompanySelection,
    };
  },
});
</script>
