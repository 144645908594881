<!-- client/src/components/signup/lt/LTSignupFlow.vue -->

<template>
  <div class="container py-5 pt-3 pt-xl-5">
    <ProgressNavComponent
      v-if="signupStep > 1 && !changingInformationDisabled"
      :steps="navSteps"
      :setStep="setStep"
      :numberSteps="true"
    />

    <!-- STEP 1: Country selection and onboarding type -->
    <div v-if="shownStep.step_name === LTStepName.CHOOSE_ONBOARDING">
      <CountryPickerComponent
        :accountData="accountData"
        :handleUpdateAccountData="handleUpdateAccountData"
      />

      <ChooseOnboardingComponent
        :addAuthorizationAgreementFlow="createAuthorizationAccountFlow"
        :addNewDeviceFlow="createNewDeviceFlow"
        :existingCompanies="existingCompanies"
        :countryLocale="'lt'"
        @selectExistingCompany="handleSelectExistingCompany"
        @resetCompanySelection="handleResetCompanySelection"
      />
    </div>

    <!-- STEP 2: Company/User Type Selection and Configuration -->
    <div v-if="shownStep.step_name === LTStepName.CONTACT_REVIEW">
      <UserTypeSelectionComponent
        v-if="shouldShowUserTypeSelection"
        :userType="userType"
        :handleUpdateUserType="handleUpdateUserType"
      />

      <MultipleUploadComponent
        v-if="shouldShowPoAUpload"
        :accountData="accountData"
        :hasGenerator="hasGenerator"
        :setLoading="setLoading"
        :uploadItems="POA_UPLOAD_ITEMS"
        :defaultUploadUrl="FILE_UPLOAD_URL"
        @uploadComplete="handleUploadComplete"
        class="mb-4"
      />

      <ContactReviewConfigurationComponent
        v-if="companyIndex !== -1"
        :key="companyIndex"
        :accountData="accountData"
        :companyIndex="companyIndex"
        :userType="userType"
        :shouldShowCompanyFields="userType !== 'private'"
        @handleUpdateCompany="$emit('handleUpdateCompany', $event)"
        @updateAccountData="handleUpdateAccountData"
        @selectExistingCompany="handleSelectExistingCompany"
        @resetCompanySelection="handleResetCompanySelection"
        class="mb-4"
      />
    </div>

    <!-- DEVICE Step 3: Necessary document upload for registering a new device-->

    <MultipleUploadComponent
      v-if="shownStep.step_name === LTStepName.MULTIPLE_UPLOAD"
      :accountData="accountData"
      :hasGenerator="hasGenerator"
      :setLoading="setLoading"
      :uploadItems="NEW_DEVICE_UPLOAD_ITEMS"
      :defaultUploadUrl="FILE_UPLOAD_URL"
      @uploadComplete="handleUploadComplete"
      class="mb-4"
    />

    <!-- DEVICE Step 4: Generator license select and any extra data associated with new device -->
    <div v-if="shownStep.step_name === LTStepName.GENERATOR_LICENSE">
      <GeneratorLicenseReviewComponent
        v-if="generatorIndex !== -1"
        :accountData="accountData"
        :companyIndex="companyIndex"
        @handleUpdateGenerator="$emit('handleUpdateGenerator', $event)"
        class="mb-4"
      />

      <CompanyInfoReviewConfigurationComponent
        v-if="companyIndex !== -1"
        :key="companyIndex"
        :accountData="accountData"
        :companyIndex="companyIndex"
        :existingDataOverride="true"
        :userType="userType"
        @handleUpdateCompany="$emit('handleUpdateCompany', $event)"
        @updateAccountData="handleUpdateAccountData"
        @selectExistingCompany="handleSelectExistingCompany"
        @resetCompanySelection="handleResetCompanySelection"
        class="mb-4"
      />
    </div>

    <!-- DEVICE Step 5 / AUTH Step 3: Contract review -->
    <ContractReviewConfigurationComponent
      v-if="
        shownStep.step_name === LTStepName.CONTRACT_REVIEW &&
        companyIndex !== -1
      "
      :accountData="accountData"
      :setLoading="setLoading"
      :requiresPoA="requiresPoA"
      @createContainerForStartSigningReady="storeCreateContainerForStartSigning"
      @disableChangingInformation="handleDisableChangingInformation"
      class="mb-4"
    />

    <!-- Buttons -->
    <div
      v-if="signupStep > 1 && !changingInformationDisabled"
      class="d-flex justify-content-between w-100"
    >
      <button
        class="btn btn-secondary me-2"
        :disabled="isLoading"
        @click="setStep(signupStep - 1)"
      >
        {{ $t("signup.navsteps.back") }}
      </button>

      <button
        class="btn btn-primary"
        :disabled="isLoading"
        @click="
          shownStep.step_name === LTStepName.CONTRACT_REVIEW
            ? createContainerForStartSigningFunction()
            : setStep(signupStep + 1)
        "
      >
        {{
          shownStep.step_name === LTStepName.CONTRACT_REVIEW
            ? $t("signup.navsteps.to_signature_page")
            : $t("signup.navsteps.next")
        }}
      </button>
    </div>
    <!-- Loading overlay, shown only when isLoading is true -->
    <LoadingOverlayComponent v-if="isLoading" />
    <!-- AccountDataDebugComponent for debugging -->
    <AccountDataDebugComponent
      :accountData="accountData"
      class="mb-4"
    ></AccountDataDebugComponent>
  </div>
</template>

<script lang="ts">
import { defineComponent, watch, ref, computed, onMounted, inject } from "vue";
import AccountDataDebugComponent from "@/components/common/AccountDataDebugComponent.vue";
import LoadingOverlayComponent from "@/components/common/LoadingOverlayComponent.vue";
import ContactReviewConfigurationComponent from "@/components/signup/lt/ContactReviewConfigurationComponent.vue";
import CompanyInfoReviewConfigurationComponent from "@/components/signup/lt/CompanyInfoReviewConfigurationComponent.vue";
import ContractReviewConfigurationComponent from "@/components/signup/lt/ContractReviewConfigurationComponent.vue";
import GeneratorLicenseReviewComponent from "@/components/signup/lt/GeneratorLicenseReviewComponent.vue";
import ProgressNavComponent from "@/components/signup/ProgressNavComponent.vue";
import type { Account } from "@/types";
import { useQueryManager } from "@/composables/useQueryManager";
import { useStepFunctions, StepConfig } from "@/composables/useStepFunctions";
import useVuelidate from "@vuelidate/core";
import { accountUpdater } from "@/composables/account/accountUpdater";
import CountryPickerComponent from "@/components/signup/CountryPickerComponent.vue";
import { useI18n } from "vue-i18n";
import MultipleUploadComponent from "@/components/signup/MultipleUploadComponent.vue";

import ChooseOnboardingComponent from "@/components/signup/ChooseOnboardingComponent.vue";
import UserTypeSelectionComponent from "@/components/signup/UserTypeSelectionComponent.vue";

enum LTStepName {
  CHOOSE_ONBOARDING,
  CONTACT_REVIEW,
  MULTIPLE_UPLOAD,
  GENERATOR_LICENSE,
  CONTRACT_REVIEW,
}

const POA_UPLOAD_ITEMS = [
  {
    fileKey: "lt_power_of_attorney",
    supportedFormats: "common.pdf_file_format",
  },
];

const NEW_DEVICE_UPLOAD_ITEMS = [
  {
    fileKey: "lt_inspection_report",
  },
  {
    fileKey: "lt_property_limit_scheme",
  },
];

const FILE_UPLOAD_URL = "/api/lt/upload-generator-file";

export default defineComponent({
  name: "LTSignupFlow",
  components: {
    AccountDataDebugComponent,
    ContactReviewConfigurationComponent,
    CompanyInfoReviewConfigurationComponent,
    ContractReviewConfigurationComponent,
    ProgressNavComponent,
    LoadingOverlayComponent,
    CountryPickerComponent,
    MultipleUploadComponent,
    GeneratorLicenseReviewComponent,
    ChooseOnboardingComponent,
    UserTypeSelectionComponent,
  },
  props: {
    accountData: {
      type: Object as () => Account,
      required: true,
    },
    handleUpdateAccountData: {
      type: Function as unknown as () => (updatedAccountData: Account) => void,
      required: true,
    },
    companyIndex: {
      type: Number,
      required: true,
    },
    generatorIndex: {
      type: Number,
      required: true,
    },
    companyIdQueryString: {
      type: Number,
      required: true,
    },
  },
  emits: [
    "handleUpdateCompany",
    "handleUpdateGenerator",
    "createCompanyIfMissing",
    "removeEmptyCompanyIfExists",
  ],
  setup(props, { emit }) {
    const createContainerForStartSigningFunction = ref(() => undefined);

    const isLoading = ref(true);
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;
    const { updateQuery, getQueryValue, parsers } = useQueryManager();
    const signupStep = getQueryValue("step", (value) =>
      value ? parseInt(value, 10) : 1
    );
    const addAuthorizationAgreement = getQueryValue(
      "addAuthorizationAgreement",
      parsers.boolean
    );

    const v$ = useVuelidate();
    const { t } = useI18n();
    const requiresPoA = computed(() => {
      return (
        userType.value === "company" &&
        props.companyIndex !== -1 &&
        !addAuthorizationAgreement.value
      );
    });
    const isAdmin = !!props.accountData.admin_account_id;

    const userType = getQueryValue<"private" | "company" | undefined>(
      "userType",
      parsers.userType
    );

    const existingCompanies = computed(() => {
      return props.accountData.companies.filter(
        (company) =>
          company.id !== undefined &&
          company.country === "LT" &&
          company.documents.length > 0
      );
    });

    const hasGenerator = computed(() => {
      return props.generatorIndex !== -1;
    });

    const postStepCallback = async (config: StepConfig) => {
      try {
        if (checkValidity()) {
          // Since data is valid we can post it if we can post it
          if (config.apiEndpoint) {
            await postAccountData(
              config.apiEndpoint,
              onAccountUpdated,
              props.companyIdQueryString
            );
          }
          return true;
        }
      } catch {
        return false;
      } finally {
        isLoading.value = false;
      }
      return false;
    };

    const STEP_CONFIG: Record<LTStepName, StepConfig> = {
      [LTStepName.CHOOSE_ONBOARDING]: {
        step_name: LTStepName.CHOOSE_ONBOARDING,
        title: t("signup.navsteps.lt.choose_onboarding"),
        titleKey: "signup.navsteps.lt.choose_onboarding",
      },
      [LTStepName.CONTACT_REVIEW]: {
        step_name: LTStepName.CONTACT_REVIEW,
        title: t("signup.navsteps.lt.contact_review"),
        titleKey: "signup.navsteps.lt.contact_review",
        apiEndpoint: "/api/lt/update-company",
        postStepCallback: postStepCallback,
      },
      [LTStepName.MULTIPLE_UPLOAD]: {
        step_name: LTStepName.MULTIPLE_UPLOAD,
        title: t("signup.navsteps.lt.multiple_upload"),
        titleKey: "signup.navsteps.lt.multiple_upload",
        postStepCallback: postStepCallback,
      },
      [LTStepName.GENERATOR_LICENSE]: {
        step_name: LTStepName.GENERATOR_LICENSE,
        title: t("signup.navsteps.lt.generator_license"),
        titleKey: "signup.navsteps.lt.generator_license",
        apiEndpoint: "/api/lt/update-generator",
        postStepCallback: postStepCallback,
      },
      [LTStepName.CONTRACT_REVIEW]: {
        step_name: LTStepName.CONTRACT_REVIEW,
        title: t("signup.navsteps.lt.contract_review"),
        titleKey: "signup.navsteps.lt.contract_review",
        postStepCallback: postStepCallback,
      },
    };

    const flowSteps = computed(() => {
      if (addAuthorizationAgreement.value) {
        return [
          STEP_CONFIG[LTStepName.CHOOSE_ONBOARDING],
          STEP_CONFIG[LTStepName.CONTACT_REVIEW],
          STEP_CONFIG[LTStepName.CONTRACT_REVIEW],
        ];
      } else {
        return [
          STEP_CONFIG[LTStepName.CHOOSE_ONBOARDING],
          STEP_CONFIG[LTStepName.CONTACT_REVIEW],
          STEP_CONFIG[LTStepName.MULTIPLE_UPLOAD],
          STEP_CONFIG[LTStepName.GENERATOR_LICENSE],
          STEP_CONFIG[LTStepName.CONTRACT_REVIEW],
        ];
      }
    });

    const { shownStep, setStep, navSteps } = useStepFunctions(
      flowSteps,
      signupStep
    );

    const setLoading = (value: boolean) => {
      isLoading.value = value;
    };

    const onAccountUpdated = async (
      _: Account,
      company_id: number,
      response: any
    ) => {
      isLoading.value = false;

      const { generator_id = undefined } = response.data || {};

      // This is here to only add the new company_id to the query parameters in case of authorization
      // agreement when company was created
      await updateQuery({
        company_id: company_id,
        ...(generator_id && { generator_id: generator_id.toString() }),
      });
    };

    const { postAccountData } = accountUpdater(
      props.accountData,
      props.handleUpdateAccountData
    );

    const checkValidity = () => {
      v$.value.$touch();
      return !v$.value.$error;
    };

    const shouldShowUserTypeSelection = computed(() => {
      // Only show if no existing company is selected that has finished registration
      if (props.companyIndex === -1) {
        return true;
      } else {
        return !props.accountData.companies[props.companyIndex].has_registered;
      }
    });

    const shouldShowPoAUpload = computed(() => {
      return (
        !addAuthorizationAgreement.value &&
        userType.value === "company" &&
        props.companyIndex === -1
      );
    });

    const storeCreateContainerForStartSigning = (
      createContainerForStartSigning: () => undefined
    ) => {
      createContainerForStartSigningFunction.value =
        createContainerForStartSigning;
    };

    const changingInformationDisabled = ref(false);
    const handleDisableChangingInformation = () => {
      changingInformationDisabled.value = true;
    };

    const handleUpdateUserType = async (
      type: "private" | "company" | undefined
    ) => {
      await updateQuery({ userType: type });
    };

    const handleUploadComplete = async (responseData: {
      accountData: Account;
      generator_id: string;
      company_id: string;
    }) => {
      const newAccountData = responseData.accountData;
      const generator_id = responseData.generator_id;
      const company_id = responseData.company_id;
      props.handleUpdateAccountData(newAccountData);
      await updateQuery({
        generator_id: generator_id,
        company_id: company_id,
      });
    };

    const createAuthorizationAccountFlow = async () => {
      await updateQuery({ addAuthorizationAgreement: "true", step: "2" });
    };

    const createNewDeviceFlow = async () => {
      await updateQuery({ addAuthorizationAgreement: "false", step: "2" });
    };

    const handleSelectExistingCompany = async (companyId: number) => {
      isLoading.value = true;

      const company = props.accountData.companies.find(
        (c) => c.id === companyId
      );

      // Lithuanian personal ID numbers are 11 digits long
      const newUserType =
        company?.registry_code?.length === 11 ? "private" : undefined;

      try {
        await updateQuery({
          company_id: companyId.toString(),
          userType: newUserType,
          step: "2",
        });
        emit("removeEmptyCompanyIfExists");
      } catch (error) {
        globalErrorHandler(error);
      } finally {
        isLoading.value = false;
      }
    };

    const handleResetCompanySelection = () => {
      updateQuery({
        company_id: undefined,
        userType: undefined,
        generator_id: undefined,
        step: "1",
      });
      emit("removeEmptyCompanyIfExists");
    };

    onMounted(async () => {
      // lmao
      isLoading.value = false;
    });

    // Watch user type selection and add a new company if needed
    watch(
      userType,
      (newValue) => {
        if (
          newValue === "private" ||
          (newValue === "company" && addAuthorizationAgreement.value)
        ) {
          emit("createCompanyIfMissing");
        } else {
          emit("removeEmptyCompanyIfExists");
        }
      },
      { immediate: true }
    );

    return {
      navSteps,
      shownStep,
      signupStep,
      setStep,
      isLoading,
      setLoading,
      hasGenerator,
      existingCompanies,
      handleUploadComplete,
      storeCreateContainerForStartSigning,
      createContainerForStartSigningFunction,
      addAuthorizationAgreement,
      createAuthorizationAccountFlow,
      createNewDeviceFlow,
      handleSelectExistingCompany,
      handleResetCompanySelection,
      handleDisableChangingInformation,
      changingInformationDisabled,
      requiresPoA,
      isAdmin,
      shouldShowUserTypeSelection,
      shouldShowPoAUpload,
      handleUpdateUserType,
      userType,
      LTStepName,
      POA_UPLOAD_ITEMS,
      NEW_DEVICE_UPLOAD_ITEMS,
      FILE_UPLOAD_URL,
    };
  },
});
</script>
