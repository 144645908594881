<!-- client/src/components/signup/ProgressNavComponent.vue -->

<template>
  <ul class="nav nav-pills mb-2 mb-sm-3 mb-xl-5 nav-fill">
    <li class="nav-item" v-for="(step, index) in steps" :key="index">
      <a
        class="nav-link px-1"
        :class="{
          active: signupStep === index + 1,
          disabled: !step.isAvailable || signupStep === index + 1,
        }"
        @click.prevent="setParentStep(index + 1)"
        role="button"
      >
        <span v-if="numberSteps">{{ index + 1 }}.</span>
        {{ step.title }}
      </a>
    </li>
  </ul>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import { useRoute } from "vue-router";
import type { Step } from "../../types";

export default defineComponent({
  name: "ProgressNavComponent",
  props: {
    steps: {
      type: Array as () => Step[],
      required: true,
    },
    setStep: {
      type: Function as PropType<(step: number) => void>,
      required: true,
    },
    numberSteps: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const route = useRoute();
    const signupStep = computed(() => {
      const step = parseInt(route.query.step as string);
      return Number.isNaN(step) ? 1 : step;
    });

    const setParentStep = (step: number) => {
      if (props.steps[step - 1] === null) {
        return;
      }
      if (props.steps[step - 1] && props.steps[step - 1].isAvailable) {
        props.setStep(step);
      }
    };
    return { signupStep, setParentStep };
  },
});
</script>

<style scoped>
.contact-review {
  .col-form-label {
    text-align: left;
    font-weight: bold;
  }
  h4 {
    color: #007bff;
  }
  .btn-outline-secondary {
    padding: 0.3rem 0.2rem;
  }
}
.nav-pills .nav-link {
  border-radius: 30px;
}

.nav-pills .nav-item {
  margin-left: 0.15rem;
  margin-right: 0.15rem;
}

.nav-link.active {
  background-color: var(--phoenix-gray-200);
  color: var(--phoenix-text-color);
  font-weight: bold;
}

.nav-link:not(.active):hover {
  background-color: var(--phoenix-gray-200);
  color: var(--phoenix-nav-link-color);
  font-weight: bold;
  transition-duration: 200ms;
  transition-property: background-color, font-weight;
  transition-timing-function: ease-out;
}
</style>
